<template>
  <div class="home">
    <!-- 头部 -->
    <div class="head">
      <Navigator />
      <h3>江苏秀圆果信息科技有限公司</h3>
      <p>Jiangsu Xiuyuanguo Information Technology Co., Ltd</p>
    </div>
    <!-- 头部 -->
    <!-- content -->
    <Content />
    <!-- content -->
    <!-- 地图 -->
    <Map />
    <!-- 地图 -->
    <!-- 资质 -->
    <div class="file">
      <h4>公司资质及知识产权</h4>
      <div class="fileImg">
        <template v-for="(item, i) in fileArr">
          <div :key="i" class="divI">
            <img :src="item.imgSrc" alt="" :class="'img' + i" />
            <p>{{ item.title }}</p>
          </div>
        </template>
      </div>
    </div>
    <!-- 资质 -->
    <!-- 合作伙伴 -->
    <div class="friend">
      <h4>合作伙伴</h4>
      <div class="friendArr">
        <template v-for="(item, i) in friendArr">
          <img :src="item.imgSrc" alt="" :key="i" :class="'img' + i" />
        </template>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <!-- 底部 -->
    <Footer />
    <!-- 底部 -->
  </div>
</template>
<script>
export default {
  components: {
    Content: () => import("./compontent/Content"),
    Map: () => import("./compontent/Map"),
  },
  data() {
    return {
      fileArr: [
        {
          imgSrc: require("@/assets/img/fileImg1.png"),
          title: "国家高新技术企业",
        },
        {
          imgSrc: require("@/assets/img/fileImg2.png"),
          title: "4项实用新型专利",
        },
        {
          imgSrc: require("@/assets/img/fileImg3.png"),
          title: "30项软件著作权",
        },
      ],
      friendArr: [
        { imgSrc: require("@/assets/img/friend1.png") },
        { imgSrc: require("@/assets/img/friend2.png") },
        { imgSrc: require("@/assets/img/friend3.png") },
        { imgSrc: require("@/assets/img/friend4.png") },
        { imgSrc: require("@/assets/img/friend5.png") },
        { imgSrc: require("@/assets/img/friend6.png") },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .head {
    width: 100%;
    height: 10.85rem;
    overflow: hidden;
    background: url(../../assets/img/home-bj.jpg) no-repeat;
    background-size: 100% 100%;
    h3 {
      font-size: 1rem;
      font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.18rem;
      text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
      margin-top: 5.23rem;
      text-align: center;
    }
    p {
      font-size: 0.1rem;
      font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.46rem;
      text-shadow: 0px 1px 1px rgba(108, 108, 108, 0.25);
      text-align: center;
    }
  }
  .file {
    text-align: center;
    width: 16.09rem;
    height: 6.5rem;
    margin: auto;
    h4 {
      margin: 0.92rem 0 0.72rem;
      font-size: 0.72rem;
      font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
      font-weight: bold;
      color: #093c7f;
      line-height: 0.7rem;
    }
    .fileImg {
      height: 5rem;
      display: flex;
      justify-content: space-around;
      .divI {
        height: 4.5rem;
        position: relative;
        text-align: center;
        .img0 {
          width: 3.62rem;
          height: 2.75rem;
          margin-top: 0.38rem;
        }
        img {
          width: 2.92rem;
          height: 3.53rem;
        }
        p {
          font-size: 0.51rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #093c7f;
          text-align: center;
          margin-top: 0.28rem;
          width: 5rem;
          position: absolute;
          bottom: 0;
          left: -20%;
        }
      }
    }
  }
  .friendArr {
    /* height: 4rem; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .img0 {
      width: 6.6rem;
      height: 1.89rem;
      margin-left: -1rem;
    }
    .img1 {
      width: 3.89rem;
      height: 1.89rem;
      margin: 0 0.51rem;
    }
    .img2 {
      width: 5.43rem;
      height: 2.2rem;
    }
    .img3 {
      width: 2.56rem;
      height: 2rem;
      margin: 1rem 0;
    }
    .img4 {
      width: 1.79rem;
      height: 1.84rem;
      margin: 1rem;
      margin-left: 3rem;
    }
    .img5 {
      width: 4.76rem;
      height: 2.41rem;
      margin: 1rem 0;
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .head {
    width: 100%;
    height: 8.09rem;
    overflow: hidden;
    text-align: center;
    background: url(../../assets/img/home-bj.jpg) no-repeat;
    background-size: 100% 100%;
    h3 {
      height: 1.4rem;
      font-size: 1rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #fff;
      line-height: 1.17rem;
      text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
      margin-top: 2.23rem;
      //margin-left: 8.1rem;
    }
    p {
      height: 0.56rem;
      font-size: 0.4rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #ffffff;
      line-height: 0.47rem;
      text-shadow: 0rem 0.04rem 0.04rem rgba(108, 108, 108, 0.25);
      //margin-left: 8.38rem;
    }
  }
  .file {
    text-align: center;
    width: 16.09rem;
    height: 6.5rem;
    margin: auto;
    h4 {
      margin: 0.92rem 0 0.72rem;
      font-size: 0.3rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #093c7f;
      line-height: 0.7rem;
    }
    .fileImg {
      height: 5rem;
      display: flex;
      justify-content: space-around;
      .divI {
        height: 4.5rem;
        position: relative;
        text-align: center;
        .img0 {
          width: 3.62rem;
          height: 2.75rem;
          margin-top: 0.38rem;
        }
        img {
          width: 2.91rem;
          height: 3.52rem;
        }
        p {
          font-size: 0.24rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #093c7f;
          // line-height: 0.28rem;
          text-align: center;
          margin-top: 0.28rem;
          position: absolute;
          bottom: 0;
          left: 20%;
        }
      }
    }
  }
  .friendArr {
    height: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .img0 {
      width: 4.08rem;
      height: 0.9rem;
    }
    .img1 {
      width: 2.4rem;
      height: 1.17rem;
      margin: 0 2.06rem 0 1.67rem;
    }
    .img2 {
      width: 3.35rem;
      height: 1.17rem;
      margin-right: 1rem;
    }
    .img3 {
      width: 1.58rem;
      height: 1.23rem;
    }
    .img4 {
      width: 1.12rem;
      height: 1.16rem;
      margin: 0 1rem;
      margin-left: 1.5rem;
    }
    .img5 {
      width: 2.95rem;
      height: 1.49rem;
    }
  }
}
.home {
  height: 100%;
  position: relative;

  .friend {
    text-align: center;
    width: 16.09rem;
    margin: auto;
    h4 {
      margin: 0.92rem 0 0.72rem;
      font-size: 0.3rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #093c7f;
      line-height: 0.7rem;
    }
  }
}
</style>
